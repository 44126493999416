@mixin default-overrides {
  hr {
    margin: 0;
  }
  .form-floating .form-label {
    color: var(--bs-dark);
  }
  .pxn-boilerplate .pxn-hero {
    margin-bottom: 0;
  }
  .nav-tabs {
    border-bottom: 0;
    a:hover {
      border-bottom: 0;
    }
    .active {
      border: 1px solid transparent !important;
    }
  }
  .pxn-modal .pxn-input {
    margin-bottom: 1rem;
  }
  .App.non-editable .ql-bubble a {
    &::before,
    &::after {
      display: none;
    }
  }
}
@include default-overrides;

@mixin login-page {
  .App .login {
    padding-top: 1rem;
    padding-bottom: 1rem;
    .pxn-input {
      margin-bottom: 1rem;
    }
  }
}
@include login-page;
@mixin page-rules {
  .page {
    .page-block,
    .page-block-content {
      &.nopad-body > .card > .card-body {
        padding: 0;
      }
      > .card > .card-header {
        h6,
        h5,
        h4,
        h3,
        h2,
        h1 {
          margin-bottom: 0;
        }
        h6,
        h5,
        h4 {
          font-size: 1em;
        }
        h3 {
          font-size: 1.17em;
        }
        h2 {
          font-size: 1.5em;
        }
        h1 {
          font-size: 2em;
        }
      }
      > .card > .card-footer {
        padding: 0;
      }
      margin-bottom: 1rem;
    }
    .page-block {
      .page-block-content {
        .pxn-input-wysiwyg label {
          display: none;
        }
        .ql-container {
          font-size: 1rem;
          font-family: var(--bs-font-sans-serif);
          &.ql-bubble:not(.ql-disabled) a {
            white-space: pre-wrap;
          }
        }
        .ql-editor {
          overflow: hidden;
          padding: 0;
        }
        .ql-font-monospace {
          font-family: var(--bs-font-monospace);
        }
        .ql-toolbar.ql-snow,
        .ql-container.ql-snow {
          border: 0;
        }
        .ql-toolbar.ql-snow {
          background-color: var(--bs-white);
        }
        .ql-tooltip {
          z-index: 99999;
        }
      }
    }
    .page-block:last-child {
      margin-bottom: 0;
    }
    .page-block-content:last-child {
      margin-bottom: 0;
    }
    .page-block.block-type-carousel {
      .slick-slide > div {
        font-size: 0;
      }
      .slick-list {
        background-color: #000000;
      }
      .carousel-center-mode,
      .thumbnail-pagination {
        .slick-slide {
          opacity: 0.5;
          transition: opacity 300ms linear;
          &:hover {
            opacity: 0.75;
          }
          &.slick-center {
            opacity: 1;
          }
        }
      }
      .thumbnail-pagination,
      .carousel-focus-on-select {
        .slick-slide .img {
          cursor: pointer;
        }
      }
    }
    .page-block.block-type-content {
      .upload-images-icon {
        svg:nth-child(1) {
          font-size: 1.25rem;
        }
        svg:nth-child(2) {
          font-size: 0.6rem;
          position: absolute;
          left: 30%;
          top: 20%;
          color: var(--bs-gray);
        }
      }
      .linked-image-icon {
        svg:nth-child(1) {
          font-size: 1.25rem;
        }
        svg:nth-child(2) {
          font-size: 0.8rem;
          position: absolute;
          left: 30%;
          top: 15%;
          color: var(--bs-gray);
        }
      }
    }
    .page-block.block-type-spacer {
      margin-top: 0;
      margin-bottom: 0;
    }
    .page-controls {
      margin-bottom: 1rem;
      button {
        margin-left: 1rem;
        margin-top: 1rem;
      }
    }
  }
}
@include page-rules;

@mixin carousel-dark-controls {
  button.slick-arrow:before,
  .slick-dots > li > button:before,
  .slick-dots > li.slick-active > button:before {
    color: var(--bs-dark);
  }
}
@include carousel-dark-controls;
@mixin carousel-light-controls {
  button.slick-arrow:before,
  .slick-dots > li > button:before,
  .slick-dots > li.slick-active > button:before {
    color: var(--bs-light);
  }
}
@mixin blue-links {
  @include carousel-dark-controls;
  --bs-link-color: var(--bs-blue);
  --bs-link-hover-color: var(--bs-blue);
}
@mixin light-links {
  @include carousel-light-controls;
  --bs-link-color: var(--bs-light);
  --bs-link-hover-color: var(--bs-light);
}
@mixin dark-links {
  @include carousel-dark-controls;
  --bs-link-color: var(--bs-dark);
  --bs-link-hover-color: var(--bs-dark);
}
@mixin link-color-rules {
  .pxn-theme-blue-links {
    @include blue-links;
    .pxn-theme-light-links {
      @include light-links;
    }
    .pxn-theme-dark-links {
      @include dark-links;
    }
  }
  .pxn-theme-light-links {
    @include light-links;
    .pxn-theme-blue-links {
      @include blue-links;
    }
    .pxn-theme-dark-links {
      @include dark-links;
    }
  }
  .pxn-theme-dark-links {
    @include dark-links;
    .pxn-theme-blue-links {
      @include blue-links;
    }
    .pxn-theme-light-links {
      @include light-links;
    }
  }
}
@include link-color-rules;

@mixin border-colors {
  .card-border-danger > .card {
    --bs-card-border-color: var(--bs-danger);
  }

  .card-border-dark > .card {
    --bs-card-border-color: var(--bs-dark);
  }

  .card-border-info > .card {
    --bs-card-border-color: var(--bs-info);
  }

  .card-border-light > .card {
    --bs-card-border-color: var(--bs-light);
  }

  .card-border-primary > .card {
    --bs-card-border-color: var(--bs-primary);
  }

  .card-border-secondary > .card {
    --bs-card-border-color: var(--bs-secondary);
  }

  .card-border-success > .card {
    --bs-card-border-color: var(--bs-success);
  }

  .card-border-transparent > .card {
    --bs-card-border-color: var(--bs-transparent);
  }

  .card-border-warning > .card {
    --bs-card-border-color: var(--bs-warning);
  }

  .card-border-white > .card {
    --bs-card-border-color: var(--bs-white);
  }
}
@include border-colors;

@mixin active-submenu-themes {
  .nav-active-submenu-theme-blue {
    .dropdown-menu {
      --bs-dropdown-link-active-bg: var(--bs-blue);
      --bs-dropdown-link-active-color: var(--bs-light);
    }
  }
  .nav-active-submenu-theme-cyan {
    .dropdown-menu {
      --bs-dropdown-link-active-bg: var(--bs-cyan);
      --bs-dropdown-link-active-color: var(--bs-dark);
    }
  }
  .nav-active-submenu-theme-danger {
    .dropdown-menu {
      --bs-dropdown-link-active-bg: var(--bs-danger);
      --bs-dropdown-link-active-color: var(--bs-light);
    }
  }
  .nav-active-submenu-theme-dark {
    .dropdown-menu {
      --bs-dropdown-link-active-bg: var(--bs-dark);
      --bs-dropdown-link-active-color: var(--bs-light);
    }
  }
  .nav-active-submenu-theme-gray {
    .dropdown-menu {
      --bs-dropdown-link-active-bg: var(--bs-gray);
      --bs-dropdown-link-active-color: var(--bs-light);
    }
  }
  .nav-active-submenu-theme-gray-dark {
    .dropdown-menu {
      --bs-dropdown-link-active-bg: var(--bs-gray-dark);
      --bs-dropdown-link-active-color: var(--bs-light);
    }
  }
  .nav-active-submenu-theme-green {
    .dropdown-menu {
      --bs-dropdown-link-active-bg: var(--bs-green);
      --bs-dropdown-link-active-color: var(--bs-light);
    }
  }
  .nav-active-submenu-theme-indigo {
    .dropdown-menu {
      --bs-dropdown-link-active-bg: var(--bs-indigo);
      --bs-dropdown-link-active-color: var(--bs-light);
    }
  }
  .nav-active-submenu-theme-info {
    .dropdown-menu {
      --bs-dropdown-link-active-bg: var(--bs-info);
      --bs-dropdown-link-active-color: var(--bs-dark);
    }
  }
  .nav-active-submenu-theme-light {
    .dropdown-menu {
      --bs-dropdown-link-active-bg: var(--bs-light);
      --bs-dropdown-link-active-color: var(--bs-dark);
    }
  }
  .nav-active-submenu-theme-orange {
    .dropdown-menu {
      --bs-dropdown-link-active-bg: var(--bs-orange);
      --bs-dropdown-link-active-color: var(--bs-light);
    }
  }
  .nav-active-submenu-theme-pink {
    .dropdown-menu {
      --bs-dropdown-link-active-bg: var(--bs-pink);
      --bs-dropdown-link-active-color: var(--bs-light);
    }
  }
  .nav-active-submenu-theme-primary {
    .dropdown-menu {
      --bs-dropdown-link-active-bg: var(--bs-primary);
      --bs-dropdown-link-active-color: var(--bs-light);
    }
  }
  .nav-active-submenu-theme-purple {
    .dropdown-menu {
      --bs-dropdown-link-active-bg: var(--bs-purple);
      --bs-dropdown-link-active-color: var(--bs-light);
    }
  }
  .nav-active-submenu-theme-red {
    .dropdown-menu {
      --bs-dropdown-link-active-bg: var(--bs-red);
      --bs-dropdown-link-active-color: var(--bs-light);
    }
  }
  .nav-active-submenu-theme-secondary {
    .dropdown-menu {
      --bs-dropdown-link-active-bg: var(--bs-secondary);
      --bs-dropdown-link-active-color: var(--bs-light);
    }
  }
  .nav-active-submenu-theme-success {
    .dropdown-menu {
      --bs-dropdown-link-active-bg: var(--bs-success);
      --bs-dropdown-link-active-color: var(--bs-light);
    }
  }
  .nav-active-submenu-theme-teal {
    .dropdown-menu {
      --bs-dropdown-link-active-bg: var(--bs-teal);
      --bs-dropdown-link-active-color: var(--bs-dark);
    }
  }
  .nav-active-submenu-theme-warning {
    .dropdown-menu {
      --bs-dropdown-link-active-bg: var(--bs-warning);
      --bs-dropdown-link-active-color: var(--bs-dark);
    }
  }
  .nav-active-submenu-theme-yellow {
    .dropdown-menu {
      --bs-dropdown-link-active-bg: var(--bs-yellow);
      --bs-dropdown-link-active-color: var(--bs-dark);
    }
  }
}
@include active-submenu-themes;

@mixin active-tab-themes {
  .nav-active-tab-theme-blue {
    .nav-tabs {
      --bs-nav-tabs-link-active-bg: var(--bs-blue);
      --bs-nav-tabs-link-active-color: var(--bs-light);
    }
  }
  .nav-active-tab-theme-cyan {
    .nav-tabs {
      --bs-nav-tabs-link-active-bg: var(--bs-cyan);
      --bs-nav-tabs-link-active-color: var(--bs-dark);
    }
  }
  .nav-active-tab-theme-danger {
    .nav-tabs {
      --bs-nav-tabs-link-active-bg: var(--bs-danger);
      --bs-nav-tabs-link-active-color: var(--bs-light);
    }
  }
  .nav-active-tab-theme-dark {
    .nav-tabs {
      --bs-nav-tabs-link-active-bg: var(--bs-dark);
      --bs-nav-tabs-link-active-color: var(--bs-light);
    }
  }
  .nav-active-tab-theme-gray {
    .nav-tabs {
      --bs-nav-tabs-link-active-bg: var(--bs-gray);
      --bs-nav-tabs-link-active-color: var(--bs-light);
    }
  }
  .nav-active-tab-theme-gray-dark {
    .nav-tabs {
      --bs-nav-tabs-link-active-bg: var(--bs-gray-dark);
      --bs-nav-tabs-link-active-color: var(--bs-light);
    }
  }
  .nav-active-tab-theme-green {
    .nav-tabs {
      --bs-nav-tabs-link-active-bg: var(--bs-green);
      --bs-nav-tabs-link-active-color: var(--bs-light);
    }
  }
  .nav-active-tab-theme-indigo {
    .nav-tabs {
      --bs-nav-tabs-link-active-bg: var(--bs-indigo);
      --bs-nav-tabs-link-active-color: var(--bs-light);
    }
  }
  .nav-active-tab-theme-info {
    .nav-tabs {
      --bs-nav-tabs-link-active-bg: var(--bs-info);
      --bs-nav-tabs-link-active-color: var(--bs-dark);
    }
  }
  .nav-active-tab-theme-light {
    .nav-tabs {
      --bs-nav-tabs-link-active-bg: var(--bs-light);
      --bs-nav-tabs-link-active-color: var(--bs-dark);
    }
  }
  .nav-active-tab-theme-orange {
    .nav-tabs {
      --bs-nav-tabs-link-active-bg: var(--bs-orange);
      --bs-nav-tabs-link-active-color: var(--bs-light);
    }
  }
  .nav-active-tab-theme-pink {
    .nav-tabs {
      --bs-nav-tabs-link-active-bg: var(--bs-pink);
      --bs-nav-tabs-link-active-color: var(--bs-light);
    }
  }
  .nav-active-tab-theme-primary {
    .nav-tabs {
      --bs-nav-tabs-link-active-bg: var(--bs-primary);
      --bs-nav-tabs-link-active-color: var(--bs-light);
    }
  }
  .nav-active-tab-theme-purple {
    .nav-tabs {
      --bs-nav-tabs-link-active-bg: var(--bs-purple);
      --bs-nav-tabs-link-active-color: var(--bs-light);
    }
  }
  .nav-active-tab-theme-red {
    .nav-tabs {
      --bs-nav-tabs-link-active-bg: var(--bs-red);
      --bs-nav-tabs-link-active-color: var(--bs-light);
    }
  }
  .nav-active-tab-theme-secondary {
    .nav-tabs {
      --bs-nav-tabs-link-active-bg: var(--bs-secondary);
      --bs-nav-tabs-link-active-color: var(--bs-light);
    }
  }
  .nav-active-tab-theme-success {
    .nav-tabs {
      --bs-nav-tabs-link-active-bg: var(--bs-success);
      --bs-nav-tabs-link-active-color: var(--bs-light);
    }
  }
  .nav-active-tab-theme-teal {
    .nav-tabs {
      --bs-nav-tabs-link-active-bg: var(--bs-teal);
      --bs-nav-tabs-link-active-color: var(--bs-dark);
    }
  }
  .nav-active-tab-theme-warning {
    .nav-tabs {
      --bs-nav-tabs-link-active-bg: var(--bs-warning);
      --bs-nav-tabs-link-active-color: var(--bs-dark);
    }
  }
  .nav-active-tab-theme-white {
    .nav-tabs {
      --bs-nav-tabs-link-active-bg: var(--bs-white);
      --bs-nav-tabs-link-active-color: var(--bs-dark);
    }
  }
  .nav-active-tab-theme-yellow {
    .nav-tabs {
      --bs-nav-tabs-link-active-bg: var(--bs-yellow);
      --bs-nav-tabs-link-active-color: var(--bs-dark);
    }
  }
}
@include active-tab-themes;
@mixin settings-component-rules {
  .settings-component {
    .pxn-card-container {
      margin-bottom: 1rem;
    }
    .pxn-card-container.backups {
      margin-bottom: 0;
    }
    .pxn-card-container.delete-page {
      margin-bottom: 0;
    }
  }
}
@include settings-component-rules;
