@import url('https://fonts.googleapis.com/css?family=Neucha|Roboto|Fira+Code');

h1,
h2,
h3,
h4,
h5,
h6,
.ql-font-serif {
  font-family: 'Neucha', cursive, serif !important;
}

.ql-font-monospace {
  font-family: 'Fira Code', var(--bs-font-monospace) !important;
}

body,
main,
header,
footer,
.navbar,
.pxn-hero,
.page-block,
.page-block-content {
  transition: background-color 500ms linear;
}

p,
a {
  transition: color 250ms linear;
}

.pxn-boilerplate .pxn-hero {
  padding-top: 1em;
  padding-bottom: 1em;
  .custom-me {
    .custom-monogram {
      margin: 0;
      padding-top: 1rem;
      padding-bottom: 1rem;
      padding-right: 0;
      img {
        transition: filter 500ms linear;
      }
    }
    .custom-name {
      padding-left: 0;
      display: flex;
      flex-direction: row;
      align-self: center;
      height: 100%;
    }
  }
}
@mixin monogram-dark {
  .custom-monogram img {
    filter: initial;
  }
}
@mixin monogram-light {
  .custom-monogram img {
    filter: invert(100%);
  }
}
.pxn-theme-light-links {
  @include monogram-light;
  .pxn-theme-dark-links,
  .pxn-theme-blue-links {
    @include monogram-dark;
  }
}
.pxn-theme-dark-links,
.pxn-theme-blue-links {
  @include monogram-dark;
  .pxn-theme-light-links {
    @include monogram-light;
  }
}

.page-block a {
  font-weight: bold;
}

.pxn-spinner-container {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.spinner-border {
  width: 5em !important;
  height: 5em !important;
  margin: 0;
}

body {
  --bs-danger: #F07178;
  --bs-dark: #292D3E;
  --bs-info: #89DDFF;
  --bs-primary: #82AAFF;
  --bs-purple: #C792EA;
  --bs-secondary: #676E95;
  --bs-success: #C3E88D;
  --bs-warning: #FFCB6B;
}

.bg-dark {
  background-color: #32374D !important;
}

.pxn-theme-danger,
.pxn-theme-primary,
.pxn-theme-success {
  @include monogram-dark;
  --bs-link-color: #000000;
  --bs-link-hover-color: #000000;
  color: #000000;
}

.path-thoughts- {
  --bs-primary: var(--bs-purple);
}

.blockext-openpgp {
  .pxn-input-textarea {
    margin-bottom: 1rem;
    font-family: var(--bs-font-monospace);
  }
  .btn-group {
    margin-bottom: 1rem;
  }
}

.App.non-editable {
  .custom-hidden {
    display: none;
  }
}
